.App {
  text-align: center;
}
.main-div {
}
.main-table-div {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .heading {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: large;
    color: black;
    text-decoration: underline;
    padding-top: 1rem;
  }
  .label {
    color: black;
    padding-top: 1rem;
  }
}
.btn-download {
  border: none;
  background-color: transparent;
  color: blue;
  cursor: pointer;
}
.table-div {
  /* margin: 5%; */
  max-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: auto;
  display: inline-block;
}
.export-csv-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.downloadbtn {
  background: rgb(30 208 126);
  color: black;
  font-weight: bold;
  border-radius: 7px;
  text-decoration: none;
  padding: 10px 10px;
  margin: unset;
  border: none;
  cursor: pointer;
}
table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
  font-size: 12px;
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word !important;
}

.heading {
}

.date-panel {
  display: flex;
  gap: 5rem;
  padding: 1rem 0;
  .date-input {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it is on top of other content */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value (0.5 in this case) for transparency */
  opacity: 0.5;
}

.custom-separator {
  border-top: 1px dashed #666; /* Dashed line */
  margin: 20px 0;
  padding: 0;
  text-align: center;
}

.react-calendar {
  width: 250px !important;
  .react-calendar__month-view__weekdays {
    font-size: 0.633em !important;
  }
  .react-calendar__tile {
    padding: 5px !important;
    font-size: 0.733em !important;
  }
}
